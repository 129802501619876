.import {
  min-height: 30vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;
  padding-inline: 30px;
  border-radius: 32px;
  background-color: rgba(246, 246, 246, 1);
  background: linear-gradient(
    128deg,
    rgba(169, 74, 243, 0.2),
    rgba(169, 74, 243, 0)
  );
}

.title {
  margin: 50px 30px;
  padding-top: 20px;
}

.title h2 {
  font-size: 2.3rem;
  margin-block-end: 20px;
}

.title p {
  line-height: 2;
}

.btn {
  width: 40%;
  margin-top: 30px;
  padding-block: 1rem;
}

.icons {
  background: url(../../assets/icons.svg);
  background-repeat: no-repeat;
  background-position: 0% 70%;
}

@media (max-width: 900px) {
  .import {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    text-align: center;
    background: rgba(246, 246, 246, 1);
    border-radius: 32px 32px 0 0;
    min-height: 80vh;
    padding-inline: 0;
    gap: 10px;
  }

  .title {
    margin: 20px;
  }

  .title h2 {
    font-size: 1.8rem;
  }

  .btn {
    width: 90%;
    margin-bottom: 20px;
  }

  .icons {
    background-size: cover;
    background-position: 90% 50%;
    background-repeat: repeat-y;
  }
}
