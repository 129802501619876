.btn {
  background-color: #fa5a5f;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border: 0;
  border-radius: 5px;
  font-weight: 600;
  box-shadow: -3px 1.5px black;
  transition: 500ms;
  width: 100%;
  cursor: pointer;
}

.btn:active,
.btn:hover {
  background-color: #fd353c;
  transform: scale(0.96);
}

