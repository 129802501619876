.form {
    display: flex;
    margin-block: 30px;
    width: 100%;
    margin-inline: auto;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.input {
    display: block;
    max-width: 600px;
    width: 100%;
    border: 0;
    padding: 0.8rem;
    border-radius: 5px;
}

.input::placeholder {
    opacity: 0.5;
}

.input:focus {
    outline: 0;
}

.btn {
    padding-block: 0.8rem;
    width: calc(30% + 30px);
}

@media (max-width: 768px) {
    .form {
        flex-direction: column;
    }

    .btn {
        padding-block: 0.8rem;
        width: 100%;
    }

    .input {
        text-align: center;
    }
}